//Polyfill
import "core-js";

//Can't resolve 'regenerator-runtime/runtime'が表示されたとき(async awaitを使う場合など)
//import  regeneratorRuntime  from  "regenerator-runtime" ;

//bootstrapのmodalを読み込み
import "/node_modules/bootstrap/js/dist/modal";

//jqueryの読み込み
import $ from "jquery";

// モーダルウィンドウを開く
$(".modal-btn").each(function(){
  $(this).on("click",function(e){
    e.preventDefault();
    const target = $(this).data("target");
    const modal = document.getElementById(target);
    $(modal).find(".md-overlay,.md-contents").fadeIn();
  });
});
$(".modal-close,.md-close").on("click",function(){
  $(".md-overlay,.md-contents").fadeOut();
});

// トップへ戻る
$(function(){
  var pagetop = $('#btn-pagetop');
  pagetop.hide();
  $(window).scroll(function () {
     if ($(this).scrollTop() > 100) {
          pagetop.fadeIn();
     } else {
          pagetop.fadeOut();
     }
  });
  pagetop.click(function () {
     $('body, html').animate({ scrollTop: 0 }, 500);
     return false;
  });
});

// ハンバーガー
$(function() {
  $('.header-spmenu-btn--icon').on('click', function() {
    $('body').toggleClass('is-open');
  });
});


window.addEventListener("DOMContentLoaded", () => {
  // モーダルを取得
  const modal = document.getElementById("modal");
  const before_modal = document.getElementById("before_modal");
  // モーダルを開く
  const openModalBtns = document.querySelectorAll(".example-single-box--gallery .modalOpen");
  const before_openModalBtns = document.querySelectorAll(".example-single--jirei--gallery .modalOpen");
  // モーダルを閉じる
  const closeModalBtns = document.querySelectorAll(".example-single-box--gallery .modalClose");
  const before_closeModalBtns = document.querySelectorAll(".example-single--jirei--gallery .modalClose");

  // Swiperの設定
  const swiper = new Swiper(".swiper.modalInSlider", {
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    spaceBetween: 0, //任意のマージン
  });

  const before_swiper = new Swiper(".swiper.before_modalInSlider", {
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    spaceBetween: 0, //任意のマージン
  });




  // モーダルのボタンクリック
  openModalBtns.forEach((openModalBtn) => {
    openModalBtn.addEventListener("click", () => {
      // data-modalで設定したスライド番号を取得
      const modalIndex = openModalBtn.getAttribute('data-modal');
      swiper.slideTo(modalIndex);
      modal.classList.add("is-active");
    });
  });

  // モーダルの閉じるボタンクリック
  closeModalBtns.forEach((closeModalBtn) => {
    closeModalBtn.addEventListener("click", () => {
      modal.classList.remove("is-active");
    });
  });

  // モーダルのボタンクリック
  before_openModalBtns.forEach((before_openModalBtn) => {
    before_openModalBtn.addEventListener("click", () => {
      // data-modalで設定したスライド番号を取得
      const before_modalIndex = before_openModalBtn.getAttribute('before-modal');
      before_swiper.slideTo(before_modalIndex);
      before_modal.classList.add("is-active");
      console.log(before_modalIndex);
    });
  });

  // モーダルの閉じるボタンクリック
  before_closeModalBtns.forEach((before_closeModalBtn) => {
    before_closeModalBtn.addEventListener("click", () => {
      before_modal.classList.remove("is-active");
    });
  });
});

//かいちく1000 地域を選択
$(function(){
  $('.ten_million__property--search--select').on('click', function() {
    $('.ten_million__property--search--checkbox').slideToggle();
    $(this).toggleClass('open');
  })
});

//買築1000に含まれるリフォーム内容
$(function(){
  $('.ten_million__contents--title').on('click', function() {
    $(this).next().slideToggle();
    $(this).toggleClass('open');
  })
});

// 買築1000チェックボックス要素を取得
const checkboxes = document.querySelectorAll('.ten_million__property--search input[type="checkbox"]');
function handleCheckboxChange() {
  const label = this.parentElement; // label要素を取得
  // console.log(label);
  if (this.checked) { // チェックされたら
    label.classList.add('checked'); // checkedクラスを追加
  } else { // チェックが外れたら
    label.classList.remove('checked'); // checkedクラスを削除
  }
}
// 全てのチェックボックスにイベントを追加
checkboxes.forEach((checkbox) => {
  checkbox.addEventListener('change', handleCheckboxChange);
});



// 各リンクのクリックイベントにリスナーを追加
var links = document.querySelectorAll('.ten_million__slide--link--btn');
for (var i = 0; i < links.length; i++) {
  links[i].addEventListener('click', function(event) {
    // クリックされたリンクの親要素であるスライドを取得
    var slide = event.target.closest('.swiper-slide');
    // スライド内のh3タグ内のテキストを取得
    var ten_million = slide.querySelector('.ten_million__slide--title').innerText;
    // hiddenフィールドに設定
    document.querySelector('#ten_million__title').value = ten_million;
  });
}

//influencerのポップアップ部分のスライド
var swiper = new Swiper(".influencerSwiper", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});


//モーダルの処理
document.querySelectorAll('.popup_btn').forEach(function(elem){
  const popup = elem.nextElementSibling
  elem.onclick = function() {
    popup.style.display='flex'
  }
  popup.querySelector('.influencer-popup--bg').onclick = function() {
    popup.style.display='none'
  }
  popup.querySelector('.close-btn').onclick = function() {
    popup.style.display='none'
  }
})